import styled from 'styled-components';

interface StyledChordProps {
	readonly active: boolean;
	readonly length: number;
}
export const StyledChord = styled.div<StyledChordProps>`
	background-color: ${({ active }) =>
		active ? 'var(--foreground)' : 'var(--background)'};
	color: ${({ active }) =>
		active ? 'var(--background)' : 'var(--foreground)'};
	border-radius: 6px;
	padding: 20px 4px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column: ${({ length }) => `span ${length}`};
	position: relative;
`;
export const ChordName = styled.div``;
export const ChordKey = styled.span``;
export const ChordFlavour = styled.span`
	text-transform: initial;
`;

interface StyledMoveChordButtonProps {
	readonly direction: -1 | 1;
}
export const StyledMoveChordButton = styled.button<StyledMoveChordButtonProps>`
	background-color: transparent;
	width: 12px;
	height: 12px;
	position: absolute;
	padding: 0;
	cursor: pointer;

	${({ direction }) => direction === -1 && `left: 0;`}
	${({ direction }) => direction === 1 && `right: 0;`}
	border-top: 8px solid transparent;
	border-right: 12px solid
		${({ direction }) =>
			direction === -1 ? `var(--orange)` : `transparent`};
	border-bottom: 8px solid transparent;
	border-left: 12px solid
		${({ direction }) =>
			direction === 1 ? `var(--orange)` : `transparent`};

	&:hover {
		border-right: 12px solid
			${({ direction }) =>
				direction === -1 ? `var(--foreground)` : `transparent`};
		border-left: 12px solid
			${({ direction }) =>
				direction === 1 ? `var(--foreground)` : `transparent`};
	}
`;

import styled from 'styled-components';

export const StyledArrangements = styled.div`
	padding: 2px 10px;
	background: var(--background);
	border-radius: 7px;
`;

interface StyledArrangementProps {
	readonly isActive: boolean;
}
export const StyledArrangement = styled.div<StyledArrangementProps>`
	cursor: pointer;
	margin: 10px 0;
	border-radius: 3px;
	padding: 10px 10px 12px;
	background: ${({ isActive }) => (isActive ? 'var(--foreground)' : 'var(--off-background)')};
	color: ${({ isActive }) => (isActive ? 'var(--background)' : 'var(--foreground)')};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

import { useDispatch } from 'react-redux';
import { StyledMoveChordButton } from './styles';
import { swapChords } from '../../store/sectionsSlice';
import { Id } from '../../types';
import { useAppSelector } from '../../app/hooks';
import {
	selectMovingChordId,
	selectNextChordId,
	selectPrevChordId
} from '../../store/appSlice';
import { MouseEvent } from 'react';

interface Props {
	direction: -1 | 1;
	parentSectionId: Id;
}

export default function MoveChordButton({ direction, parentSectionId }: Props) {
	const dispatch = useDispatch();
	const movingChordId = useAppSelector(selectMovingChordId);
	const prevChord = useAppSelector(
		selectPrevChordId(parentSectionId, movingChordId)
	);
	const nextChord = useAppSelector(
		selectNextChordId(parentSectionId, movingChordId)
	);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		dispatch(
			swapChords({
				sectionId: parentSectionId,
				chordOneId: movingChordId,
				chordTwoId: direction === -1 ? prevChord : nextChord
			})
		);
	};
	return (
		<StyledMoveChordButton
			className="exclude-click-outside"
			direction={direction}
			onClick={handleClick}
		/>
	);
}

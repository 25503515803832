export default function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			className="play-icon"
			// width={14 }
			// height={17 }
			viewBox="0 0 14 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M14 8.5L0.499999 16.7272L0.5 0.272758L14 8.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}

import { useRef } from 'react';
import { useClickAway } from 'react-use';
import { Modal, ModalCloseButton, ModalFoot, ModalHead, SaveButton } from '../../app/styles';
import { useAppSelector } from '../../app/hooks';
import { selectSections } from '../../store/sectionsSlice';
import {
	selectActiveArrangementId,
	selectActiveArrangementName,
	selectBpm,
	selectTimeSignature,
	selectView,
	setView
} from '../../store/appSlice';
import { useDispatch } from 'react-redux';
import { useSaveArrangement } from './hooks';
import SaveBody from './SaveBody';

export default function Save() {
	const dispatch = useDispatch();

	const sections = useAppSelector(selectSections);
	const bpm = useAppSelector(selectBpm);
	const timeSignature = useAppSelector(selectTimeSignature);

	const ref = useRef(null);
	const saveRef = useRef<HTMLButtonElement>(null);

	const arrangementName = useAppSelector(selectActiveArrangementName);
	const activeArrangementId = useAppSelector(selectActiveArrangementId);

	const isVisible = useAppSelector(selectView) === 'save';
	const closeModal = () => {
		dispatch(setView('main'));
	};

	const handleSaveArrangement = useSaveArrangement(
		{
			name: arrangementName ?? '',
			sections,
			bpm,
			timeSignature
		},
		activeArrangementId
	);

	useClickAway(ref, () => {}); //closeModal());
	return isVisible ? (
		<Modal ref={ref}>
			<ModalCloseButton
				onClick={closeModal}
				title="close this modal popup"
				aria-label="close this modal poup"
			/>

			<div>
				<ModalHead>Save Arrangement</ModalHead>
				<SaveBody saveRef={saveRef} />
			</div>

			<ModalFoot>
				<SaveButton
					ref={saveRef}
					disabled={arrangementName === ''}
					onClick={handleSaveArrangement}
					title={arrangementName !== '' ? undefined : 'No changes detected'}
				>
					Save Arrangement
				</SaveButton>
			</ModalFoot>
		</Modal>
	) : null;
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Chord, Id, Section } from '../types';
import { loadLocalStorageState, saveLocalStorageState } from '../utils/localStorage';

export type View = 'main' | 'editApp' | 'editSection' | 'editChord' | 'save' | 'load';

export type Mode = 'edit' | 'play';
export interface AppState {
	mode: Mode;
	view: View;
	init: boolean;
	activeSectionId: Id | null;
	activeChordId: Id | null;
	movingChordId: Id | null;
	activeArrangementId: Id | null;
	activeArrangementName: string | null;
	bpm: number;
	timeSignature: [number, number];
	featuresEnabled: number[];
}

const initialState: AppState = {
	mode: 'edit',
	view: 'main',
	init: false,
	activeSectionId: null,
	activeChordId: null,
	movingChordId: null,
	activeArrangementId: null,
	activeArrangementName: null,
	bpm: loadLocalStorageState().app?.bpm ?? 120,
	timeSignature: loadLocalStorageState().app?.timeSignature ?? [4, 4],
	featuresEnabled: []
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setMode: (state, action: PayloadAction<Mode>) => {
			state.mode = action.payload;
		},
		setView: (state, action: PayloadAction<View>) => {
			state.view = action.payload;
		},
		init: state => {
			state.init = true;
		},
		setActiveSectionId: (state, action: PayloadAction<Id | null>) => {
			state.activeSectionId = action.payload;
		},
		setActiveChordId: (state, action: PayloadAction<Id | null>) => {
			state.activeChordId = action.payload;
		},
		setMovingChordId: (state, action: PayloadAction<Id | null>) => {
			state.movingChordId = action.payload;
		},
		setActiveArrangementId: (state, action: PayloadAction<Id | null>) => {
			state.activeArrangementId = action.payload;
		},
		setActiveArrangementName: (state, action: PayloadAction<string | null>) => {
			state.activeArrangementName = action.payload;
		},
		setBpm: (state, action: PayloadAction<number>) => {
			state.bpm = action.payload;
			saveLocalStorageState({
				app: { ...state }
			});
		},
		setTimeSignature: (state, action: PayloadAction<[number, number]>) => {
			state.timeSignature = action.payload;
			saveLocalStorageState({
				app: { ...state }
			});
		},
		setFeaturesEnabled: (state, action: PayloadAction<number[]>) => {
			state.featuresEnabled = action.payload;
		}
	}
});

export const {
	setMode,
	setView,
	init,
	setActiveSectionId,
	setActiveChordId,
	setMovingChordId,
	setActiveArrangementId,
	setActiveArrangementName,
	setBpm,
	setTimeSignature,
	setFeaturesEnabled
} = appSlice.actions;

export default appSlice.reducer;

const getSectionById = (id: Id, sections: Section[]) => sections.find(section => section.id === id);

const getChordByIDAndSectionId = (id: Id | null, sectionId: Id | null, sections: Section[]) => {
	if (id != null && sectionId != null) {
		const section = getSectionById(sectionId, sections);
		if (section) {
			const foundChord = section.chords.find(chord => chord.id === id);
			if (foundChord) {
				return foundChord;
			} else {
				console.error('Missing chord');
			}
		}
	}
	return null;
};

export const selectView = (state: RootState) => state.app.view;
export const selectMode = (state: RootState) => state.app.mode;
export const selectInit = (state: RootState) => state.app.init;
export const selectActiveChordId = (state: RootState) => state.app.activeChordId;
export const selectActiveSectionId = (state: RootState) => state.app.activeSectionId;
export const selectActiveChord = (state: RootState): Chord | null => {
	const id = state.app.activeChordId;
	const sectionId = state.app.activeSectionId;
	return getChordByIDAndSectionId(id, sectionId, state.sections);
};
export const selectMovingChordId = (state: RootState) => state.app.movingChordId;
export const selectMovingChord =
	(sectionId: Id) =>
	(state: RootState): Chord | null => {
		const id = state.app.movingChordId;
		return getChordByIDAndSectionId(id, sectionId, state.sections);
	};
export const selectActiveSection = (state: RootState): Section | null => {
	const id = state.app.activeSectionId;
	if (id) {
		const section = state.sections.find(section => section.id === id);
		return section || null;
	}
	return null;
};
export const selectActiveArrangementId = (state: RootState) => state.app.activeArrangementId;
export const selectActiveArrangementName = (state: RootState) => state.app.activeArrangementName;

export const selectBpm = (state: RootState) => state.app.bpm;
export const selectTimeSignature = (state: RootState) => state.app.timeSignature;
export const selectFeaturesEnabled = (state: RootState) => state.app.featuresEnabled;
export const selectHasFeature = (featureId: number) => (state: RootState) =>
	state.app.featuresEnabled.includes(featureId);

const getPrevNextChordId = (
	sectionId: Id,
	chordId: Id,
	direction: -1 | 1,
	sections: Section[]
): number | null => {
	const section = getSectionById(sectionId, sections);
	const currentChordIndex = section?.chords.findIndex(chord => chord.id === chordId);
	if (currentChordIndex != null) {
		const prevOrNextChordIndex = currentChordIndex + direction;
		if (prevOrNextChordIndex >= 0) {
			return section?.chords[prevOrNextChordIndex]?.id ?? null;
		}
	}
	return null;
};
export const selectPrevChordId = (sectionId: Id, chordId: Id | null) => (state: RootState) => {
	return chordId != null ? getPrevNextChordId(sectionId, chordId, -1, state.sections) : null;
};
export const selectNextChordId = (sectionId: Id, chordId: Id | null) => (state: RootState) => {
	return chordId != null ? getPrevNextChordId(sectionId, chordId, 1, state.sections) : null;
};

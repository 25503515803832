import { useRef } from 'react';
import { useClickAway } from 'react-use';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalFoot,
	ModalHead,
	SaveButton
} from '../../app/styles';
import { useAppSelector } from '../../app/hooks';
import { selectActiveArrangementId, selectView, setView } from '../../store/appSlice';
import { useDispatch } from 'react-redux';
import { useLoadArrangement } from './hooks';
import Arrangements from '../Arrangements';
import { loadLocalStorageArrangements } from '../../utils/localStorage';

export default function Load() {
	const dispatch = useDispatch();
	const arrangements = loadLocalStorageArrangements();

	const ref = useRef(null);
	const saveRef = useRef(null);

	const activeArrangementId = useAppSelector(selectActiveArrangementId);
	const isVisible = useAppSelector(selectView) === 'load';
	const closeModal = () => dispatch(setView('main'));

	const handleLoadArrangement = useLoadArrangement(activeArrangementId);

	useClickAway(ref, () => {}); //closeModal());
	return isVisible ? (
		<Modal ref={ref}>
			<ModalCloseButton
				onClick={closeModal}
				title="close this modal popup"
				aria-label="close this modal poup"
			/>
			<div>
				<ModalHead>Load Arrangement</ModalHead>

				<ModalBody>
					<Arrangements arrangements={arrangements} />
				</ModalBody>
			</div>

			<ModalFoot>
				<SaveButton
					ref={saveRef}
					disabled={activeArrangementId === null}
					onClick={handleLoadArrangement}
					title={
						activeArrangementId === null
							? 'No arrangement selected'
							: 'Load selected arrangement'
					}
				>
					Load Arrangement
				</SaveButton>
			</ModalFoot>
		</Modal>
	) : null;
}

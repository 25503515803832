import { useAppSelector } from '../../app/hooks';
import { selectActiveChordId } from '../../store/appSlice';
import { Chord as ChordType } from '../../types';
import { calculateChordRows } from '../../utils/calculateChordRows';
import ChordRow from './ChordRow';

interface Props {
	chord: ChordType;
	chordMeasureIndex: number;
}

export default function Chord({ chord, chordMeasureIndex }: Props) {
	const activeChordId = useAppSelector(selectActiveChordId);
	const placementIsOdd = chordMeasureIndex % 2 === 1;
	const rows = calculateChordRows(chord.length, placementIsOdd);

	return rows.map((length, i) => (
		<ChordRow
			key={i}
			active={activeChordId === chord.id}
			chord={chord}
			length={length}
			rowIndex={i}
			rowsLength={rows.length}
		/>
	));
}

export function assertIsNode(e: EventTarget | null): asserts e is Node {
	if (!e || !('nodeType' in e)) {
		throw new Error(`Node expected`);
	}
}

export function targetIsElement(e: EventTarget | null): e is Element {
	return e !== null && 'nodeType' in e && e.nodeType === Node.ELEMENT_NODE;
}

export function assertIsTypeByProperty<T>(item: unknown, propertyName: string): item is T {
	return item != null && typeof item === 'object' && item.hasOwnProperty(propertyName);
}

import {
	selectActiveChord,
	selectActiveSectionId,
	selectHasFeature,
	selectView,
	setActiveChordId,
	setActiveSectionId,
	setView
} from '../../store/appSlice';
import { useAppSelector } from '../../app/hooks';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalFoot,
	ModalHead,
	SaveButton
} from '../../app/styles';
import KeySelect from './KeySelect';
import FlavourSelect from './FlavourSelect';
import LengthInput from './LengthInput';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addChord, updateChord } from '../../store/sectionsSlice';
import DeleteChord from './DeleteChord';
import useStateUpdater from '../../hooks/useStateUpdater';
import { useClickAway } from 'react-use';
import { getChordFlavours } from '../../const/chordFlavours';
import { FeatureEnum } from '../../const/featureFlags';

export default function EditChord() {
	const hasExtraChords = useAppSelector(selectHasFeature(FeatureEnum.extrachords));
	const flavourIds = getChordFlavours(hasExtraChords).map(f => f.id);

	const dispatch = useDispatch();

	const closeModal = () => {
		dispatch(setView('main'));
		dispatch(setActiveSectionId(null));
		dispatch(setActiveChordId(null));
	};
	const sectionId = useAppSelector(selectActiveSectionId);
	const isVisible = useAppSelector(selectView) === 'editChord' && sectionId !== null;
	const activeChord = useAppSelector(selectActiveChord);

	const saveRef = useRef<HTMLButtonElement>(null);

	// state
	const [key, _setKey] = useState<number>(activeChord?.key ?? 0);
	const [flavourId, _setFlavourId] = useState<string>(activeChord?.flavourId ?? flavourIds[0]);
	const [length, _setLength] = useState(activeChord?.length ?? 1);
	const setKey = useStateUpdater(_setKey, saveRef?.current);
	const setFlavourId = useStateUpdater(_setFlavourId, saveRef?.current);
	const setLength = useStateUpdater(_setLength, saveRef?.current);

	const isDirty =
		!activeChord ||
		(activeChord &&
			(key !== activeChord.key ||
				flavourId !== activeChord.flavourId ||
				length !== activeChord.length));

	const handleSaveChord = isVisible
		? () => {
				if (isDirty && activeChord !== null) {
					dispatch(
						updateChord({
							sectionId,
							chord: {
								id: activeChord.id,
								key,
								flavourId,
								length
							}
						})
					);
				} else if (isDirty) {
					dispatch(
						addChord({
							sectionId,
							chord: {
								id: Date.now(),
								key,
								flavourId,
								length
							}
						})
					);
				}
				dispatch(setView('main'));
				dispatch(setActiveSectionId(null));
				dispatch(setActiveChordId(null));
		  }
		: undefined;

	// when first loading, if editing existing chord, load chord data
	useEffect(() => {
		if (isVisible && activeChord !== null) {
			setKey(activeChord.key);
			setFlavourId(activeChord.flavourId);
			setLength(activeChord.length);
		}
	}, [isVisible, activeChord, setKey, setFlavourId, setLength]);

	const ref = useRef(null);
	useClickAway(ref, () => closeModal());
	return isVisible ? (
		<Modal ref={ref}>
			<ModalCloseButton
				onClick={closeModal}
				title="close this modal popup"
				aria-label="close this modal poup"
			/>
			<div>
				<ModalHead>{activeChord !== null ? 'Edit' : 'Add'} Chord</ModalHead>

				<ModalBody>
					<KeySelect value={String(key)} onChange={v => setKey(Number(v))} />
					<FlavourSelect value={flavourId} onChange={setFlavourId} />
					<LengthInput value={length} onChange={setLength} />
					<DeleteChord />
				</ModalBody>
			</div>

			<ModalFoot>
				<SaveButton
					ref={saveRef}
					disabled={!isDirty}
					onClick={handleSaveChord}
					title={isDirty ? undefined : 'No changes detected'}
				>
					{activeChord !== null ? 'Save' : 'Add'} Chord
				</SaveButton>
			</ModalFoot>
		</Modal>
	) : null;
}

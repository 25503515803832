import { Arrangement as ArrangementType } from '../../types';
import { assertIsTypeByProperty } from '../../utils/types';
import Arrangement from './Arrangement';
import { StyledArrangements } from './styles';

interface Props {
	arrangements: Array<ArrangementType | Omit<ArrangementType, 'id'>>;
}
export default function Arrangements({ arrangements }: Props) {
	return (
		<StyledArrangements>
			{arrangements.map(arrangement => (
				<Arrangement
					arrangement={arrangement}
					key={
						assertIsTypeByProperty<ArrangementType>(arrangement, 'id')
							? arrangement.id
							: 'new'
					}
				/>
			))}
		</StyledArrangements>
	);
}

import { useEffect } from 'react';
import { FeatureEnum } from '../const/featureFlags';
import { useDispatch } from 'react-redux';
import { setFeaturesEnabled } from '../store/appSlice';

export default function useFeatureFlags() {
	const dispatch = useDispatch();

	// on load populate store with features
	useEffect(() => {
		const featuresEnabled: number[] = [];
		Object.keys(FeatureEnum).forEach(k => {
			const key = parseInt(k);
			const featureName = `feature${key}`;
			const localFeature = localStorage.getItem(featureName);
			if (localFeature) {
				featuresEnabled.push(key);
			}
		});
		dispatch(setFeaturesEnabled(featuresEnabled));
	}, [dispatch]);
}

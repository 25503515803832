import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
	selectMode,
	selectMovingChordId,
	setActiveChordId,
	setActiveSectionId,
	setMovingChordId,
	setView
} from '../../store/appSlice';
import { Chord as ChordType, KeyEnum } from '../../types';
import { ChordFlavour, ChordKey, ChordName, StyledChord } from './styles';
import { selectIsFirstChord, selectIsLastChord } from '../../store/sectionsSlice';
import MoveChordButton from './MoveChordButton';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import { targetIsElement } from '../../utils/types';
import { getChordFlavourById } from '../../const/chordFlavours';

interface ChordRowProps {
	chord: ChordType;
	active: boolean;
	length: number;
	rowIndex: number;
	rowsLength: number;
}

export default function ChordRow({
	chord: { key, flavourId, id, parentSectionId },
	active,
	length,
	rowIndex,
	rowsLength
}: ChordRowProps) {
	const flavour = getChordFlavourById(flavourId);

	const dispatch = useDispatch();

	const isChordMoving = useAppSelector(selectMovingChordId) === id;
	const isFirstChordOfSection = useAppSelector(selectIsFirstChord(id, parentSectionId));
	const isLastChordOfSection = useAppSelector(selectIsLastChord(id, parentSectionId));
	const mode = useAppSelector(selectMode);

	const handleClickChord = () => {
		if (mode === 'edit' && !isChordMoving) {
			dispatch(setActiveChordId(id));
			dispatch(setActiveSectionId(parentSectionId));
			dispatch(setView('editChord'));
		}
	};

	let timeOutId: NodeJS.Timeout | null = null;
	const handleLongPressStart = () => {
		timeOutId = setTimeout(() => {
			dispatch(setMovingChordId(id));
		}, 500);
	};
	const handleLongPressEnd = () => {
		if (timeOutId && !isChordMoving) clearTimeout(timeOutId);
	};

	const movingChordId = useAppSelector(selectMovingChordId);
	const handleClickOutside = ({ target }: Event) => {
		if (targetIsElement(target) && target.classList.contains('exclude-click-outside')) return;

		if (id === movingChordId) {
			dispatch(setMovingChordId(null));
		}
	};
	const ref = useRef(null);
	useClickAway(ref, e => handleClickOutside(e));
	return (
		<StyledChord
			active={active}
			id={String(id)}
			length={length}
			onClick={handleClickChord}
			onMouseDown={handleLongPressStart}
			onMouseUp={handleLongPressEnd}
			onTouchStart={handleLongPressStart}
			onTouchEnd={handleLongPressEnd}
			ref={ref}
		>
			{isChordMoving && !isFirstChordOfSection && rowIndex === 0 && (
				<MoveChordButton direction={-1} parentSectionId={parentSectionId} />
			)}
			<ChordName>
				<ChordKey>{KeyEnum[key].replace(/b/g, '♭')}</ChordKey>
				<ChordFlavour>{flavour?.shortName}</ChordFlavour>
			</ChordName>
			{isChordMoving && !isLastChordOfSection && !(rowIndex === 0 && rowsLength > 1) && (
				<MoveChordButton direction={1} parentSectionId={parentSectionId} />
			)}
		</StyledChord>
	);
}

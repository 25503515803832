export enum FeatureEnum {
	autosave,
	saveload,
	extrachords
}

// convert enum to array of strings e.g. autosave -> ['a','u','t','o','s','a','v','e']
export const featureCodesSplitString: Record<number, string[]> = Object.keys(FeatureEnum).reduce(
	(acc, key, index) => {
		acc[index] = key.split('');
		return acc;
	},
	{} as Record<number, string[]>
);

import { getChordFlavourById } from '../const/chordFlavours';
import { Note, getKeyEnumKeys } from '../types';
import { KeyEnum } from '../types';

const parseNote = (key: number): Note => {
	const keyCount = getKeyEnumKeys().length;

	let octave = 3;
	if (key >= keyCount) {
		const diff = key - keyCount;
		const additonalOctaves = Math.ceil(diff / keyCount);
		octave += additonalOctaves;
		key = diff % keyCount;
		return { name: KeyEnum[key], octave };
	} else {
		return { name: KeyEnum[key], octave };
	}
};

/**
 * Returns the notes of a chord
 * @param {number} key - a value from KeyEnum
 * @param {string} flavourId - a chord flavour id
 * @return Note[] - an array of Notes
 */
export function getChordNotes(key: number, flavourId: string): Note[] {
	const flavour = getChordFlavourById(flavourId);
	const notes: Note[] = [{ name: KeyEnum[key], octave: 3 }];
	flavour?.noteNumbers.forEach(noteNumber => notes.push(parseNote(key + noteNumber)));
	return notes;
}

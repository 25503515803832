import { featureCodesSplitString } from '../const/featureFlags';

let featureCode: number | null = null;

let featureCodes = structuredClone(featureCodesSplitString);

const reset = () => {
	featureCode = null;
	featureCodes = structuredClone(featureCodesSplitString);
};

export const handleFeatureCode = (e: KeyboardEvent) => {
	if (featureCode != null) {
		const featureKeys = featureCodes[featureCode];
		if (e.key === featureKeys[0]) {
			featureKeys.shift();
		} else {
			reset();
		}

		// code is complete
		if (featureKeys.length === 0) {
			const featureName = `feature${featureCode}`;

			// check if feature is enabled
			const localFeature = localStorage.getItem(featureName);

			if (localFeature == null) {
				// enable feature
				localStorage.setItem(featureName, 'true');
			}
			reset();
		}
	}

	if (e.key === '0') {
		featureCode = 0;
	}
	if (e.key === '1') {
		featureCode = 1;
	}
};

import { ModalBody } from '../../app/styles';
import { selectBpm, selectTimeSignature } from '../../store/appSlice';
import Arrangements from '../Arrangements';
import { Arrangement } from '../../types';
import { RefObject } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectSections } from '../../store/sectionsSlice';
import { loadLocalStorageArrangements } from '../../utils/localStorage';

interface Props {
	saveRef: RefObject<HTMLButtonElement>;
}

export default function SaveBody({ saveRef }: Props) {
	const sections = useAppSelector(selectSections);
	const bpm = useAppSelector(selectBpm);
	const timeSignature = useAppSelector(selectTimeSignature);

	const arrangements: Array<Arrangement | Omit<Arrangement, 'id'>> =
		loadLocalStorageArrangements();
	const arrangement: Omit<Arrangement, 'id'> = {
		name: 'New Arrangement',
		sections,
		bpm,
		timeSignature
	};
	arrangements.unshift(arrangement);
	return (
		<ModalBody>
			<Arrangements arrangements={arrangements} />
		</ModalBody>
	);
}

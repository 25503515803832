import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
	selectActiveArrangementId,
	selectActiveArrangementName,
	setActiveArrangementId,
	setActiveArrangementName
} from '../../store/appSlice';
import { Arrangement as ArrangementType } from '../../types';
import { StyledArrangement } from './styles';
import { assertIsTypeByProperty } from '../../utils/types';
import { Input } from '../Inputs/styles';

interface Props {
	arrangement: ArrangementType | Omit<ArrangementType, 'id'>;
}
export default function Arrangement({ arrangement }: Props) {
	const dispatch = useDispatch();

	const isNewArrangement = !assertIsTypeByProperty<ArrangementType>(arrangement, 'id');
	const activeArrangementId = useAppSelector(selectActiveArrangementId);
	const activeArrangementName = useAppSelector(selectActiveArrangementName);

	const setArrangementName = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setActiveArrangementName(e.target.value));
	};

	const isActive = isNewArrangement
		? activeArrangementId === null
		: activeArrangementId === arrangement.id;

	const handleClick = () => {
		dispatch(setActiveArrangementId(isNewArrangement ? null : arrangement.id));
		dispatch(setActiveArrangementName(arrangement.name ?? ''));
	};

	return (
		<StyledArrangement isActive={isActive} onClick={handleClick}>
			{isActive ? (
				<Input
					onChange={setArrangementName}
					placeholder="NEW ARRANGEMENT"
					value={activeArrangementName ?? ''}
				/>
			) : (
				arrangement.name
			)}
		</StyledArrangement>
	);
}

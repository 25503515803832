import { RootState } from '../app/store';
import { FeatureEnum } from '../const/featureFlags';
import { Arrangement } from '../types';

export const isFeatureEnabled = (feature: number): boolean =>
	localStorage.getItem(`feature${feature}`) === 'true';

const isAutoSaveEnabled = (): boolean => isFeatureEnabled(FeatureEnum.autosave);

export function saveLocalStorageState(stateSlice: Partial<RootState>): void {
	if (!isAutoSaveEnabled()) return;
	const existingState = JSON.parse(
		localStorage.getItem('cp_state') ?? '{"app":{},"sections":[]}'
	);
	localStorage.setItem('cp_state', JSON.stringify({ ...existingState, ...stateSlice }));
}

export function loadLocalStorageState(): Partial<RootState> {
	if (!isAutoSaveEnabled()) return {};
	return JSON.parse(localStorage.getItem('cp_state') ?? '{}');
}

export const isSaveLoadEnabled = (): boolean => isFeatureEnabled(FeatureEnum.saveload);

export function loadLocalStorageArrangements(): Arrangement[] {
	if (!isSaveLoadEnabled()) return [];
	return JSON.parse(localStorage.getItem('cp_arrangements') ?? '[]').sort(
		(a: Arrangement, b: Arrangement) => a.name.localeCompare(b.name)
	);
}

export function saveLocalStorageArrangement(arrangement: Arrangement): void {
	// console.log({ arrangement });
	if (!isSaveLoadEnabled()) return;
	const existingArrangements = loadLocalStorageArrangements();
	const arrangementToOverwriteIndex = existingArrangements.findIndex(
		a => a.id === arrangement.id
	);
	if (arrangementToOverwriteIndex > -1) {
		existingArrangements.splice(arrangementToOverwriteIndex, 1);
	}
	// console.log({ existingArrangements });

	localStorage.setItem('cp_arrangements', JSON.stringify([...existingArrangements, arrangement]));
}

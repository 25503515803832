import { useDispatch } from 'react-redux';
import { SecondaryButton, ProButton } from '../../app/styles';
import { isSaveLoadEnabled } from '../../utils/localStorage';
import { setView } from '../../store/appSlice';

export default function SaveButton() {
	const dispatch = useDispatch();
	const hasSaveLoad = isSaveLoadEnabled();

	return hasSaveLoad ? (
		<SecondaryButton onClick={() => dispatch(setView('save'))}>Save</SecondaryButton>
	) : (
		<ProButton>Save</ProButton>
	);
}

import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { selectView, setView } from '../../store/appSlice';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalHead,
	ProButton,
	PrimaryButton
} from '../../app/styles';
import DeleteButton from '../Buttons/DeleteButton';
import { clearAll } from '../../store/sectionsSlice';
import useHasNoSections from '../../hooks/useHasNoSections';
import { useClickAway } from 'react-use';
import { useRef } from 'react';
import LoadButton from './LoadButton';
import SaveButton from './SaveButton';

export default function EditApp() {
	const dispatch = useDispatch();
	const hasSections = !useHasNoSections();
	const isVisible = useAppSelector(selectView) === 'editApp';

	const closeModal = () => {
		dispatch(setView('main'));
	};
	const handleDeleteAll = () => {
		dispatch(clearAll());
		closeModal();
	};
	const ref = useRef(null);
	useClickAway(ref, () => {
		closeModal();
	});

	return isVisible ? (
		<Modal ref={ref}>
			<ModalCloseButton
				onClick={closeModal}
				title="close this modal popup"
				aria-label="close this modal poup"
			/>
			<div>
				<ModalHead>Options</ModalHead>
				<ModalBody>
					{hasSections && (
						<DeleteButton
							confirmMessage="Are you sure you want to delete all sections and chords?"
							onClick={handleDeleteAll}
							title="Delete all sections and chords"
							aria-label="Delete all sections and chords"
						>
							Clear all chords
						</DeleteButton>
					)}
					<LoadButton />
					<SaveButton />
					<ProButton>Chord Options</ProButton>
					<ProButton>Drums</ProButton>
					<ProButton>Click</ProButton>
					<PrimaryButton>View Pro features</PrimaryButton>
				</ModalBody>
			</div>
		</Modal>
	) : null;
}

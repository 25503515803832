export type Id = number;

export interface Note {
	name: string;
	octave: number;
}
export interface Chord {
	id: Id;
	key: number;
	flavourId: string;
	length: number; // in measures
	parentSectionId: Id;
}

export interface Section {
	id: Id;
	chords: Chord[];
	repeatCount: number;
	timeSignature?: string;
	bpm?: number;
}

export interface Arrangement {
	id: Id;
	name: string;
	sections: Section[];
	bpm: number;
	timeSignature: [number, number];
}

export enum KeyEnum {
	C,
	Db,
	D,
	Eb,
	E,
	F,
	Gb,
	G,
	Ab,
	A,
	Bb,
	B
}
export const getKeyEnumValues = () =>
	Object.values(KeyEnum).filter(v => typeof v === 'number') as number[]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, ...]

export const getKeyEnumKeys = () => Object.keys(KeyEnum).filter(v => isNaN(Number(v))) as string[]; // ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", ...]

import { useAppSelector } from '../../app/hooks';
import { InputContainer, Label } from '../../app/styles';
import { ChordFlavour, getChordFlavours } from '../../const/chordFlavours';
import { FeatureEnum } from '../../const/featureFlags';
import { selectHasFeature } from '../../store/appSlice';
import Select from '../Select';

interface Props {
	value: string;
	onChange: (value: string) => void;
}

export default function FlavourSelect({ value, onChange }: Props) {
	const hasExtraChords = useAppSelector(selectHasFeature(FeatureEnum.extrachords));
	const flavours: ChordFlavour[] = getChordFlavours(hasExtraChords);
	return (
		<InputContainer>
			<Label>Type:</Label>
			<Select
				options={Object.values(flavours).map(flavour => ({
					value: flavour?.id,
					label: flavour?.longName
				}))}
				value={value}
				onChange={onChange}
			/>
		</InputContainer>
	);
}

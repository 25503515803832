import { useDispatch } from 'react-redux';
import { MAX_NUMBER_OF_ARRANGEMENTS } from '../../const/limits';
import { Arrangement, Id } from '../../types';
import {
	loadLocalStorageArrangements,
	saveLocalStorageArrangement
} from '../../utils/localStorage';
import { setBpm, setTimeSignature, setView } from '../../store/appSlice';
import { loadSections } from '../../store/sectionsSlice';

export const useSaveArrangement = (
	arrangement: Omit<Arrangement, 'id'>,
	existingArrangementId: Id | null
) => {
	const dispatch = useDispatch();
	const closeModal = () => dispatch(setView('main'));

	// get existing arrangements from local storage
	const existingArrangements = loadLocalStorageArrangements();
	// const existingArrangementNames = existingArrangements.map(a => a.name);

	return () => {
		if (existingArrangements.length >= MAX_NUMBER_OF_ARRANGEMENTS) {
			// show error message
			alert('Sorry you have too many saves');
		} else if (existingArrangementId != null) {
			const confirm = window.confirm('Are you sure you want to overwrite this arrangement?');
			if (confirm) {
				// new save
				saveLocalStorageArrangement({ id: existingArrangementId, ...arrangement });
				closeModal();
			}
		} else {
			// new save
			saveLocalStorageArrangement({ id: Date.now(), ...arrangement });
			closeModal();
		}
	};
};

export const useLoadArrangement = (selectedArrangementId: Id | null) => {
	const dispatch = useDispatch();
	const closeModal = () => dispatch(setView('main'));

	const savedArrangements = loadLocalStorageArrangements();

	const selectedArrangement = savedArrangements.find(a => a.id === selectedArrangementId);

	if (selectedArrangementId == null) return;

	return () => {
		if (selectedArrangement != null) {
			dispatch(setBpm(selectedArrangement.bpm));
			dispatch(setTimeSignature(selectedArrangement.timeSignature));
			dispatch(loadSections(selectedArrangement.sections));
			closeModal();
		} else {
		}
	};
};

export interface ChordFlavour {
	id: string;
	shortName: string;
	longName: string;
	noteNumbers: number[];
}
const basicChordFlavours: ChordFlavour[] = [
	{ id: 'maj', shortName: '', longName: 'Major', noteNumbers: [4, 7] },
	{ id: 'min', shortName: 'm', longName: 'Minor', noteNumbers: [3, 7] },
	{ id: 'dim', shortName: '°', longName: 'Diminished', noteNumbers: [3, 6] },
	{ id: 'aug', shortName: '+', longName: 'Augmented', noteNumbers: [4, 8] },
	{ id: 'dom7', shortName: '7', longName: 'Dominant 7', noteNumbers: [4, 7, 10] }
];

const extraChordFlavours: ChordFlavour[] = [
	{ id: 'min7', shortName: 'm7', longName: 'Minor 7', noteNumbers: [3, 7, 10] },
	{ id: 'maj7', shortName: 'M7', longName: 'Major 7', noteNumbers: [4, 7, 11] },
	{ id: 'dim7', shortName: '°7', longName: 'Diminished 7', noteNumbers: [3, 6, 9] },
	{ id: 'aug7', shortName: '+7', longName: 'Augmented 7', noteNumbers: [4, 8, 10] },
	{ id: 'half', shortName: 'ø7', longName: 'Half Diminished 7', noteNumbers: [3, 6, 10] },
	{ id: 'min9', shortName: 'm9', longName: 'Minor 9', noteNumbers: [3, 7, 10, 14] },
	{ id: 'maj9', shortName: 'M9', longName: 'Major 9', noteNumbers: [4, 7, 11, 14] },
	{ id: 'dom9', shortName: '9', longName: 'Dominant 9', noteNumbers: [4, 7, 10, 14] },
	{ id: 'min11', shortName: 'm11', longName: 'Minor 11', noteNumbers: [3, 7, 10, 14, 17] },
	{ id: 'maj11', shortName: 'M11', longName: 'Major 11', noteNumbers: [4, 7, 11, 14, 18] }
];

export const getChordFlavours = (hasExtraChordFlavours: boolean): ChordFlavour[] => {
	return hasExtraChordFlavours
		? [...basicChordFlavours, ...extraChordFlavours]
		: basicChordFlavours;
};

export const getChordFlavourById = (flavourId: string): ChordFlavour | null => {
	const flavours = getChordFlavours(true);
	const flavour = flavours.find(f => f.id === flavourId);
	return flavour ?? null;
};
